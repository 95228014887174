<template>
  
<div>
  <bar title="true" close="true" ref="bar" titleText="Supera el reto" />
  <play-verse ref="playVerse"/>
</div>

</template>

<script>

import bar from '../components/appComponents/Bar.vue'
import playVerse from '../components/studyUtils/PlayVerse.vue'

export default {
  name: 'PlayVsbot',
  components: {
    bar,
    'play-verse':playVerse
  },
  data() {
    return {
      calculatedCaptionsMemory: [],
      countSuccessWords: 0,
      successWords:0,
      finished: false
    }
  },
  mounted(){
    let that = this
    this.$root.$on('onInitPlayVerse',function(){
      that.$store.state.currentRoute = '/home'
      that.$router.push(that.$store.state.currentRoute)
    })
    this.$root.$on('sendCaptionsPlayVerse',function(data){
      that.sendCaptions(data.captions,data.id)
    })
    this.$root.$on('onFinishChronometer',function(){
      that.$refs.playVerse.disableSend()
      that.finished = true
      if (that.$refs.playVerse.countSentences === 0) {
        that.finish()
      } 
    })
    if(this.$store.state.fromStart){
      this.init()
    }
  },
  methods: {
    init: function(){
      this.$refs.bar.setProgress(0,this.$store.state.step.successWords)
      let calculatedCaptions = this.calculatedCaptionsInitial()
      let resource = 'vsbot/attempt/'+this.$store.state.attemptId+'/audio'
      this.$refs.playVerse.initAudio(this.$store.state.step.init,this.$store.state.step.finish,this.$store.state.step.status,resource,calculatedCaptions)
    },
    sendCaptions: function(captions,id){
      this.$http.patch(this.$store.state.urlApi+'vsbot/attempt/'+this.$store.state.attemptId, {
          captions: captions
        },
        { headers: {
            Accept: 'application/json',
            Authorization: 'Bearer '+this.$store.state.accessToken
      }}).then(response => {
        let words = response.body.words.length
        this.countSuccessWords += words
        this.$refs.playVerse.notifyCorrection(id , words)

        let calculatedCaptions = this.calculatedCaptions(response.body.firstWord,response.body.words)
        this.$refs.playVerse.viewCaptions(calculatedCaptions)
        
        if (this.countSuccessWords >= this.$store.state.step.countWordsVerse || (this.finished && this.$refs.playVerse.countSentences === 0)){
          this.$refs.playVerse.disableSend()
          this.finish()
        }
      }, function () {
        if (this.finished && this.$refs.playVerse.countSentences === 0){
          this.finish()
        }
        this.$refs.playVerse.notifyCorrection(id , 0)
      });
    },
    finish: function(){
      this.$http.patch(this.$store.state.urlApi+'vsbot/attempt/'+this.$store.state.attemptId, {
          opened: false,
          timezone: this.$store.state.timezone
        },
        { headers: {
            Accept: 'application/json',
            Authorization: 'Bearer '+this.$store.state.accessToken
      }}).then(response => {
        this.$store.state.loading = false

        if (response.body.success || response.body.goalStreak){
          this.$store.state.result = { 
              redirection : '/home' 
            , buttonName : 'continuar'
            , vsbot: true
            , progressToday : {
                increaseWords : response.body.increaseWords,
                objetiveStreak : response.body.goalStreak
            }
          }
          this.$store.state.step.title = response.body.title
          this.$store.state.step.id = response.body.stepId
          this.$store.state.currentRoute = '/result'
        } else {
          this.$store.state.step.redirect = 'home'
          this.$store.state.step.message = 'Reto no superado'
          this.$store.state.currentRoute = '/instructions'
        }
        this.$router.push(this.$store.state.currentRoute)
      }, function () {
        this.$store.state.loading = false
        this.$store.state.currentRoute = this.$store.state.errorRedirect
        this.$router.push(this.$store.state.currentRoute)
      });
    },
    calculatedCaptionsInitial: function(){
      this.calculatedCaptionsMemory = []
      for (let i = 1; i <= this.$store.state.step.countWordsVerse;i++){
        this.calculatedCaptionsMemory.push({
          id: i,
          text: '***'
        })
      }
      return [
        {
          text : this.calculatedCaptionsMemory
        }
      ]
    },
    calculatedCaptions: function(firstWord,words){
      for (let i in words){
        let id = words[i].id - firstWord + 1
        for (let k in this.calculatedCaptionsMemory){
          if (this.calculatedCaptionsMemory[k].id === id){
            this.calculatedCaptionsMemory[k].text = words[i].text
            break
          }
        }
        this.successWords++
      }
      this.$refs.bar.setProgress(this.successWords,this.$store.state.step.successWords)
      return [
        {
          text : this.calculatedCaptionsMemory
        }
      ]
    }
  },
  beforeDestroy: function(){
    this.$root.$off('onInitPlayVerse')
    this.$root.$off('sendCaptionsPlayVerse')
    this.$root.$off('onFinishChronometer')
  }
};
</script>
